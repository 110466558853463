import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container text-center">
            <div className="col-md-8">
              
            </div>
            <div className="col-md-8 col-md-offset-2 section-title">
              <div className="contact-item">
                <h1>Contact Info</h1>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
            </div>
            
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; Lacuna Management 2021
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
