import React, { Component } from 'react'

export class about extends Component {
  render() {
    return (
        <div id="about">
        <div className="text-center">
          <div className="row">
            
            <div className="col-md-8 col-md-offset-2 section-title">
              <div className="about-text">
                <h2>About Us</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
               
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about
